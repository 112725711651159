import React from "react";
import moment from "moment";
import "moment/locale/hr";

function DateCell({ date }) {
  moment.locale(localStorage.getItem("language"));
  return date && <div>{date !== null ? moment(date).format("L") : "-"}</div>;
}

export default DateCell;
