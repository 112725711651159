import { useHTTPClient } from "helper";

const ValidationsService = () => {
  const { get, post } = useHTTPClient();

  const getValidationsDetails = (id) => get(`Validations/Details?ValidationShortGuid=${id}`);

  const getAllValidations = () => get("Validations/GetAll");

  const getValidation = (shortGuid) => get(`/Validations?ShortGuid=${shortGuid}`);

  const exportValidations = (body) =>
    post("Validations/ExportValidation", body, { responseType: "blob" });

  return { getValidationsDetails, getAllValidations, getValidation, exportValidations };
};

export default ValidationsService;
