import { useHTTPClient } from "helper";

const EditorService = () => {
  const { post } = useHTTPClient();

  const startEditor = async (body, config) => post("Editor/StartEditor", body, config);

  return { startEditor };
};

export default EditorService;
