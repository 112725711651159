import React from "react";
import moment from "moment";
import "moment/locale/hr";
import { MDTypography } from "components";

function DateTimeActionCell({ data }) {
  moment.locale(localStorage.getItem("language"));

  return (
    data && (
      <MDTypography
        variant="button"
        onClick={() => data.action(data.id)}
        style={{ cursor: "pointer" }}
      >
        {data !== null ? moment.utc(data.date).local().format("L LT") : "-"}
      </MDTypography>
    )
  );
}

export default DateTimeActionCell;
