import axios from "axios";
import { iamURL } from "config";

const getHeaders = (headers = {}) => ({
  "Content-Type": "application/json",
  ...headers,
});

export function InitAuth() {
  const path = "destination/initauth";
  const url = `${iamURL}/${path}`;
  return axios.post(url, "", {
    headers: getHeaders({
      redirectURI:
        process.env.NODE_ENV === "development"
          ? "https://app-test.xbrlpoint.com/app/projects?initAuth=true"
          : window.location.href,
    }),
    withCredentials: true,
  });
}

export function Ask2fa(body) {
  const path = "login/ask2fa";
  const url = `${iamURL}/${path}`;
  return axios.post(url, body, {
    headers: getHeaders(),
    withCredentials: true,
  });
}

export function Submit2fa(body) {
  const path = "login/submit2fa";
  const url = `${iamURL}/${path}`;
  return axios.post(url, body, {
    headers: getHeaders(),
    withCredentials: true,
  });
}

export function Logout() {
  const path = "destination/logout";
  const url = `${iamURL}/${path}`;
  return axios.post(url, "", {
    headers: getHeaders(),
    withCredentials: true,
  });
}

export function Ask2Reset(body) {
  const path = "reset/askreset";
  const url = `${iamURL}/${path}`;
  return axios.post(url, body, {
    headers: getHeaders(),
    withCredentials: true,
  });
}

export function ResetPassword(body, session) {
  const path = "reset/confirmreset";
  const url = `${iamURL}/${path}`;
  return axios.post(url, body, {
    headers: getHeaders({ reset_session: session }),
    withCredentials: true,
  });
}
