import { useHTTPClient } from "helper";
import _ from "lodash";

const LookupService = () => {
  const { get } = useHTTPClient();

  const getLookups = (lookups) => {
    const lookupString = _.join(lookups, ",");
    return get(`Lookups?Include=${lookupString}`);
  };

  return { getLookups };
};

export default LookupService;
