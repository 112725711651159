/* eslint-disable no-undef */
import React, { useMemo, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  useExpanded,
} from "react-table";
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  Icon,
  Autocomplete,
  Collapse,
  Grid,
  Tooltip,
} from "@mui/material";
import {
  MDBox,
  MDTypography,
  MDInput,
  MDPagination,
  DataTableHeadCell,
  DataTableBodyCell,
} from "components";
import { useTranslation } from "react-i18next";

function DataTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  dataTableHeight,
  dataTableBodyHeight,
  isLoading,
  footerEnabled,
  isCollapsible,
  isHederless,
  CollapsibleComponent,
  getTableRowProps = () => ({}),
  searchTooltip = "",
  AdditionalFilterComponent,
  shouldClearSearch = false,
  setShouldClearSearch = () => {},
}) {
  const defaultValue = entriesPerPage.defaultValue && entriesPerPage.defaultValue;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["10", "20", "50", "100", "500", "1000"];
  const columns = useMemo(() => [...table.columns], [table]);

  const { t } = useTranslation();

  // Let's say pageSize is 10 initially
  const initialPageSize = defaultValue || 100;
  const data = useMemo(() => table.rows, [table]);

  const initialState = { pageIndex: 0, pageSize: initialPageSize };

  const tableInstance = useTable(
    { columns, data, initialState, getTrProps: getTableRowProps },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
    getTrProps,
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => setPageSize(value);

  // Render the paginations
  let options;

  if (pageIndex === 0) {
    options = pageOptions.slice(0, 3);
  } else if (pageIndex === pageOptions.length - 1) {
    options = pageOptions.slice(pageIndex - 2, pageIndex + 1);
  } else {
    options = pageOptions.slice(pageIndex - 1, pageIndex + 2);
  }

  const renderPagination = options.map((option) => (
    <MDPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </MDPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  useEffect(() => () => onSearchChange(), [search]);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const searchInputRef = useRef();

  useEffect(() => {
    if (shouldClearSearch) {
      searchInputRef.current.children[0].children[0].value = "";
      onSearchChange("");
      setShouldClearSearch(false);
    }
  }, [shouldClearSearch]);

  return (
    <TableContainer
      className="dataTable"
      style={{
        height: dataTableHeight && dataTableHeight,
        marginTop: AdditionalFilterComponent != null ? "-40px" : null,
      }}
    >
      {canSearch && (
        <Grid container justifyContent="flex-end">
          {AdditionalFilterComponent != null ? <AdditionalFilterComponent /> : null}
          <Grid item xs={1.5} pr={2} pb={2}>
            <Tooltip title={t(searchTooltip)} placement="top">
              <MDInput
                ref={searchInputRef}
                placeholder={t("datatable.search")}
                value={search}
                size="small"
                fullWidth
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      )}
      <div className="tableBody" style={{ height: dataTableBodyHeight && dataTableBodyHeight }}>
        <Table {...getTableProps()}>
          {isHederless ? null : (
            <MDBox component="thead">
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) =>
                    !column.hide ? (
                      <DataTableHeadCell
                        {...column.getHeaderProps(
                          isSorted && column.sortable && column.getSortByToggleProps()
                        )}
                        width={column.width ? column.width : "auto"}
                        align={column.align ? column.align : "left"}
                        sorted={isSorted && column.sortable ? setSortedValue(column) : null}
                      >
                        {column.render("Header")}
                      </DataTableHeadCell>
                    ) : null
                  )}
                </TableRow>
              ))}
            </MDBox>
          )}
          {rows.length === 0 ? (
            <TableBody>
              <TableRow>
                <DataTableBodyCell>
                  {isLoading ? (
                    <div className="spinner" />
                  ) : (
                    <div className="noDataContainer">
                      <Icon className="noDataIcon">do_disturb</Icon>
                      <span className="noDataText">No data available</span>
                    </div>
                  )}
                </DataTableBodyCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody {...getTableBodyProps()}>
              {page.map((row, key) => {
                prepareRow(row);
                return (
                  <React.Fragment key={row.original.id}>
                    <TableRow {...row.getRowProps()} {...getTrProps(row, false)}>
                      {row.cells.map((cell) =>
                        !cell.column.hide ? (
                          <DataTableBodyCell
                            noBorder={noEndBorder && rows.length - 1 === key}
                            align={cell.column.align ? cell.column.align : "left"}
                            cellWidth={cell.column.width ? cell.column.width : "auto"}
                            {...cell.getCellProps()}
                          >
                            {cell.render("Cell")}
                          </DataTableBodyCell>
                        ) : null
                      )}
                    </TableRow>
                    {isCollapsible ? (
                      <TableRow {...getTrProps(row, true)}>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                          <Collapse in={row.isExpanded} timeout="auto" unmountOnExit>
                            <CollapsibleComponent key={row.id} {...row} />
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </TableBody>
          )}
        </Table>
      </div>
      {footerEnabled && (
        <div className="tableFooter">
          <MDBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
            height="10vh"
          >
            {pageOptions.length > 1 && (
              <MDPagination variant={pagination.variant ? pagination.variant : "gradient"}>
                <MDPagination item onClick={() => gotoPage(0)} disabled={pageIndex === 0}>
                  <Icon className="icons">first_page</Icon>
                </MDPagination>

                {renderPagination.length > 900 ? (
                  <MDBox width="5rem" mx={1}>
                    <MDInput
                      inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                      value={customizedPageOptions[pageIndex]}
                      onChange={(handleInputPagination, handleInputPaginationValue)}
                    />
                  </MDBox>
                ) : (
                  renderPagination
                )}

                <MDPagination
                  item
                  onClick={() => gotoPage(pageOptions.length - 1)}
                  disabled={pageIndex === pageOptions.length - 1}
                  color={pageIndex === pageOptions.length - 1 ? "dark" : "light"}
                >
                  <Icon className="icons">last_page</Icon>
                </MDPagination>
              </MDPagination>
            )}

            {rows.length !== 0 && (entriesPerPage || canSearch) ? (
              <MDBox style={{ marginLeft: "auto", paddingTop: "-3px" }}>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p={!showTotalEntries && pageOptions.length === 1 ? 0 : 2}
                >
                  {showTotalEntries && (
                    <MDTypography
                      variant="button"
                      color="secondary"
                      fontWeight="regular"
                      sx={{
                        textTransform: "none !important",
                        fontWeight: "400 !important",
                        lineHeight: "1.5 !important",
                      }}
                    >
                      {t("datatable.showing")} {entriesStart} {t("datatable.to")} {entriesEnd}{" "}
                      {t("datatable.of")} {rows.length} {t("datatable.entries")}
                    </MDTypography>
                  )}
                  {entriesPerPage && (
                    <Autocomplete
                      disableClearable
                      value={pageSize.toString()}
                      options={entries}
                      onChange={(event, newValue) => {
                        setEntriesPerPage(parseInt(newValue, 10));
                      }}
                      size="small"
                      sx={{ width: "5rem", marginRight: "1rem", marginLeft: "1rem" }}
                      renderInput={(params) => <MDInput {...params} />}
                    />
                  )}
                </MDBox>
              </MDBox>
            ) : null}
          </MDBox>
        </div>
      )}
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [10, 20, 50, 100, 500, 1000] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  dataTableHeight: "auto",
  isLoading: false,
  footerEnabled: true,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  dataTableHeight: PropTypes.string,
  isLoading: PropTypes.bool,
  footerEnabled: PropTypes.bool,
};

export default DataTable;
