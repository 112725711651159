import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles"; // Import the 'styled' function to create a custom styled component.

const CustomWidthTooltip = styled((props) => (
  <Tooltip
    enterDelay={1500}
    enterNextDelay={1500}
    {...props}
    classes={{ popper: props.className }}
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "23vw",
  },
}));

export default CustomWidthTooltip;
