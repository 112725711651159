import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { MDBox, DashboardNavbar } from "components";
import { useMaterialUIController, setLayout } from "context";
import { Grid } from "@mui/material";

function DashboardLayout({ children, title }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    // Get the root element or the parent container element
    const rootElement = document.getElementById("root"); // Replace "root" with the ID of your root element or container

    // Apply CSS properties to disable scrolling on the root element
    rootElement.style.overflow = "hidden";
    rootElement.style.height = "100vh";

    // Cleanup function to restore scrolling when the component is unmounted
    return () => {
      rootElement.style.overflow = "";
      rootElement.style.height = "";
    };
  }, []);
  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <MDBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 1,
        paddingTop: 2,
        height: "100%",
        position: "relative",
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <DashboardNavbar title={title} />
      <MDBox
        py={1}
        sx={({ functions: { pxToRem } }) => ({
          height: `calc(100% - ${pxToRem(75)})`,
          position: "relative",
        })}
      >
        <Grid sx={{ height: "100%" }} container>
          <Grid item xs={12} md={12} lg={12} sx={{ height: "100%" }}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  title: PropTypes.node.isRequired,
};

export default DashboardLayout;
