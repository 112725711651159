function MVSelect({ options, id, onChange, className, value }) {
  const renderOptions = () =>
    options.map((option) => (
      <option value={option.value} key={option.value}>
        {option.label}
      </option>
    ));

  return (
    <select id={id} value={value} className={className} onChange={onChange}>
      <option value={null}> </option>
      {renderOptions()}
    </select>
  );
}

export default MVSelect;
