import { useHTTPClient } from "helper";

const FootnoteSerivce = () => {
  const { get, post } = useHTTPClient();

  const getFotnotes = (id) => get(`Footnotes?ProjectShortGuid=${id}`);

  const createFootnote = (data) => post(`Footnotes`, data);

  return {
    getFotnotes,
    createFootnote,
  };
};

export default FootnoteSerivce;
