import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

function LinkCell({ link }) {
  return <StyledLink to={`/app/project/details/${link.shortGuid}`}>{link.name}</StyledLink>;
}

export default LinkCell;
