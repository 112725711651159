import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { MDButton } from "components";
import { useTranslation } from "react-i18next";
import { WarningAmber } from "@mui/icons-material";

function MDNotificationDialog({ open, setOpen, title, content, className }) {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      className={className}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ paddingLeft: "20px", paddingRight: "20px", textAlign: "center" }}
          id="alert-dialog-description"
          className="confirm-dialog-content"
        >
          <WarningAmber fontSize="large" />
        </DialogContentText>
        <DialogContentText
          sx={{ paddingLeft: "20px", paddingRight: "20px", textAlign: "center" }}
          id="alert-dialog-description"
          className="confirm-dialog-content"
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <MDButton onClick={handleClose} variant="gradient" color="dark" autoFocus>
              {t("confirm")}
            </MDButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default MDNotificationDialog;
