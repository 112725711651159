import React from "react";
import PropTypes from "prop-types";
import { Icon, Tooltip } from "@mui/material";
import { MDBox, MDButton } from "components";
import { useTranslation } from "react-i18next";

function StatusCell({ status }) {
  const { t } = useTranslation();
  let color;
  let icon;
  let title;
  let variant;

  switch (status) {
    case 1: // active - Aktivno
      color = "success";
      icon = <Icon sx={{ fontWeight: "bold" }}>done</Icon>;
      title = t("statusCell.active");
      variant = "outlined";
      break;
    case 2: // inactive - neaktivno
      color = "error";
      icon = <Icon sx={{ fontWeight: "bold" }}>close</Icon>;
      title = t("statusCell.inactive");
      variant = "outlined";
      break;
    case 3: // pending - u tijeku
      color = "info";
      icon = <Icon sx={{ fontWeight: "bold" }}>hourglass_bottom</Icon>;
      title = t("statusCell.pending");
      variant = "outlined";
      break;
    case 4: // in progress - u obradi
      color = "info";
      icon = <Icon sx={{ fontWeight: "bold" }}>edit</Icon>;
      title = t("statusCell.inprogress");
      variant = "outlined";
      break;
    case 5: // completed - dovrseno
      color = "success";
      icon = <Icon sx={{ fontWeight: "bold" }}>done</Icon>;
      title = t("statusCell.completed");
      variant = "outlined";
      break;
    case 6: // failed - neuspjelo
      color = "error";
      icon = <Icon sx={{ fontWeight: "bold" }}>priority_high</Icon>;
      title = t("statusCell.failed");
      variant = "contained";
      break;
    case 7: // submitted - poslano
      color = "info";
      icon = <Icon sx={{ fontWeight: "bold" }}>inventory</Icon>;
      title = t("statusCell.submitted");
      variant = "outlined";
      break;
    case 8: // processing - u obradi
      color = "info";
      icon = <Icon sx={{ fontWeight: "bold" }}>hourglass_bottom</Icon>;
      title = t("statusCell.processing");
      variant = "outlined";
      break;
    case 9: // processedSuccessfully - uspjesno zavrseno
      color = "success";
      icon = <Icon sx={{ fontWeight: "bold" }}>done</Icon>;
      title = t("statusCell.processedsuccessfully");
      variant = "outlined";
      break;
    case 10: // processedWithWarnings - Završeno s upozorenjima - warning
      color = "warning";
      icon = <Icon sx={{ fontWeight: "bold" }}>priority_high</Icon>;
      title = t("statusCell.processedwithwarnings");
      variant = "outlined";
      break;
    case 11: // processedWithErrors - Završeno s greškama - error
      color = "error";
      icon = <Icon sx={{ fontWeight: "bold" }}>priority_high</Icon>;
      title = t("statusCell.processedwitherrors");
      variant = "outlined";
      break;
    case 12: // generatingInstance - Generiranje instance - error
      color = "info";
      icon = <Icon sx={{ fontWeight: "bold" }}>widgets_outlined</Icon>;
      title = t("statusCell.generatinginstance");
      variant = "outlined";
      break;
    default:
      color = "error";
      icon = <Icon sx={{ fontWeight: "bold" }}>close</Icon>;
      title = t("statusCell.failed");
      variant = "outlined";
  }

  return (
    <MDBox display="flex" alignItems="center">
      <Tooltip enterDelay={1000} enterNextDelay={1000} title={title} placement="top">
        <MDBox mr={1}>
          <MDButton variant={variant} color={color} size="small" iconOnly circular disabled>
            {icon}
          </MDButton>
        </MDBox>
      </Tooltip>
    </MDBox>
  );
}

// Typechecking props for the StatusCell
StatusCell.propTypes = {
  status: PropTypes.number,
};

StatusCell.defaultProps = {
  status: 0,
};

export default StatusCell;
