import PropTypes from "prop-types";
import { MDInput } from "components";

function FormFieldPD({ label, disabled, className, id, ...rest }) {
  return (
    <MDInput
      variant="standard"
      label={label}
      fullWidth
      InputLabelProps={{ shrink: true }}
      disabled={disabled}
      className={className}
      id={id}
      {...rest}
    />
  );
}

// Setting default values for the props of FormFieldPD
FormFieldPD.defaultProps = {
  label: " ",
  disabled: false,
};

// Typechecking props for FormFieldPD
FormFieldPD.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormFieldPD;
