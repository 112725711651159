import { useHTTPClient } from "helper";

const DocumentsService = () => {
  const { get, postDoc, getDoc, post } = useHTTPClient();

  const uploadDocument = (data, id, config, sourceToken) =>
    postDoc(`Documents/Upload?shortGuid=${id}`, data, config, sourceToken);

  const getConversionStatus = (id) => get(`Projects/ConversionStatus?shortGuid=${id}`);

  const getConversionInfo = (id) => get(`Projects/ConversionInfo?shortGuid=${id}`);

  const downloadPreviewData = (id) => get(`/Documents/DownloadPreview?projectShortGuid=${id}`);

  const downloadReportZip = async (id) => getDoc(`Documents/DownloadZip?projectShortGuid=${id}`);

  const downloadXhtml = async (id) => get(`Documents/DownloadPreview?projectShortGuid=${id}`);

  const getSizeAndFormats = () => get("Documents/SizeAndFormats");

  const uploadHtml = (projectShortGuid, body, config) =>
    post(`Documents/UploadHtml?shortGuid=${projectShortGuid}`, body, config);

  return {
    uploadDocument,
    getConversionStatus,
    getConversionInfo,
    downloadPreviewData,
    downloadReportZip,
    downloadXhtml,
    getSizeAndFormats,
    uploadHtml,
  };
};

export default DocumentsService;
