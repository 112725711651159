import * as signalR from "@microsoft/signalr";
import { hubUrl } from "config";

const DEFAULT_CONFIG = {
  logger: process.env.NODE_ENV === "development" ? signalR.LogLevel.Trace : signalR.LogLevel.None,
  skipNegotiation: true,
  transport: signalR.HttpTransportType.WebSockets,
};

/**
 * @param {string} name - name of the hub
 * @param {signalR.IHttpConnectionOptions} config - config for the hub connection
 */
function signalRHubConnectionUtility(name, config = {}) {
  if (!name) throw new Error("Cannot create signalr hub connection without name param!");

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${hubUrl}${name}`, Object.assign(DEFAULT_CONFIG, config))
    .withAutomaticReconnect()
    .build();
  const unregisterHandlerFns = [];

  function registerHandler(methodName, handler) {
    connection.on(methodName, handler);
    const unregisterHandlerFn = () => connection.off(methodName, handler);
    unregisterHandlerFns.push(unregisterHandlerFn);
    return unregisterHandlerFn;
  }

  function unregisterHandlers() {
    unregisterHandlerFns.forEach((handlerFn) => handlerFn());
  }

  function start() {
    return connection.start();
  }

  function stop() {
    return connection.stop();
  }

  async function dispose() {
    unregisterHandlers();
    await stop();
  }

  return {
    registerHandler,
    start,
    dispose,
  };
}

export default signalRHubConnectionUtility;
