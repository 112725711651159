import flatpickr from "flatpickr";
import Flatpickr from "react-flatpickr";
import { english } from "flatpickr/dist/l10n/default";
import { Croatian } from "flatpickr/dist/l10n/hr";
import "flatpickr/dist/flatpickr.css";

import { useState, useRef } from "react";
import { InputAdornment, Icon } from "@mui/material";
import { MDInput } from "components";
import { useTranslation } from "react-i18next";

flatpickr.l10ns.hr.rangeSeparator = " do ";

const getLocale = (culture) => {
  switch (culture) {
    case "hr":
      return Croatian;
    default:
      return english;
  }
};

const getDateFormat = (culture) => {
  switch (culture) {
    case "hr":
      return "d.m.Y";
    default:
      return "m/d/Y";
  }
};

function MDDatePicker({ input, options, value, clearFn, ...rest }) {
  const [shouldShowClearBtn, setShouldShowClearBtn] = useState(false);
  const flatpickrRef = useRef();
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Flatpickr
      ref={flatpickrRef}
      options={{
        locale: getLocale(language),
        dateFormat: getDateFormat(language),
        ...options,
      }}
      render={({ defaultValue }, ref) => (
        <MDInput
          {...input}
          defaultValue={defaultValue}
          inputRef={ref}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {clearFn && shouldShowClearBtn && (
                  <Icon
                    fontSize="medium"
                    sx={{ cursor: "pointer", marginRight: 1 }}
                    onClick={clearFn}
                  >
                    clear
                  </Icon>
                )}
                <Icon
                  fontSize="medium"
                  sx={{ cursor: "pointer" }}
                  onClick={() => flatpickrRef?.current?.flatpickr?.toggle()}
                >
                  calendar_today
                </Icon>
              </InputAdornment>
            ),
          }}
          onMouseEnter={() => {
            if (value.length === 2 && !flatpickrRef?.current?.flatpickr?.isOpen)
              setShouldShowClearBtn(true);
          }}
          onMouseLeave={() => setShouldShowClearBtn(false)}
        />
      )}
      value={value}
      {...rest}
    />
  );
}

export default MDDatePicker;
