import { useHTTPClient } from "helper";

const CompaniesService = () => {
  const { get, post, put, del } = useHTTPClient();

  const getCompanies = ({ page, rpp }) =>
    get(`Companies?PageParams.PageIndex=${page}&PageParams.PageSize=${rpp}`);

  const createCompany = (body) => post("Companies", body);

  const getCompanyByShortGuid = (shortGuid) =>
    get(`Companies/GetByShortGuid?ShortGuid=${shortGuid}`);

  const updateCompany = (body) => put("Companies", body);

  const deleteCompany = (shortGuid) => del("Companies", { shortGuid });

  return { getCompanies, createCompany, getCompanyByShortGuid, updateCompany, deleteCompany };
};

export default CompaniesService;
