import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { MDLoadingButton } from "components";
import { useTranslation } from "react-i18next";

function MDConfirmDialog({
  open,
  setOpen,
  title,
  content,
  onConfirm,
  className,
  isLoading,
  cancelAction,
}) {
  const { t } = useTranslation();
  const handleClose = (event, reason) => {
    if (isLoading && reason === "backdropClick") return;
    setOpen(false);
  };

  const handleConfirm = async () => {
    await onConfirm();
    handleClose();
  };
  const onCancel = () => {
    if (cancelAction) cancelAction();

    handleClose();
  };

  return (
    <Dialog
      className={className}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="confirm-dialog-content">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={6} />
          <Grid item xs={3} pr={1}>
            <MDLoadingButton
              onClick={onCancel}
              variant="gradient"
              color="dark"
              disabled={isLoading}
              fullWidth
            >
              {t("cancel")}
            </MDLoadingButton>
          </Grid>
          <Grid item xs={3}>
            <MDLoadingButton
              onClick={handleConfirm}
              variant="gradient"
              color="dark"
              autoFocus
              isLoading={isLoading}
              fullWidth
            >
              {t("confirm")}
            </MDLoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default MDConfirmDialog;
