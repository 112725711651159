import { List } from "@mui/material";

function SidenavList({ children }) {
  return (
    <List
      sx={{
        px: 2,
        my: 0.3,
      }}
    >
      {children}
    </List>
  );
}

export default SidenavList;
