import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@mui/material";
import { ErrorOutlineOutlined, CheckCircleOutlineOutlined } from "@mui/icons-material";
import { MDLoadingButton } from "components";
import { useTranslation } from "react-i18next";

function MDInfoDialog({ open, setOpen, title, content, className, type }) {
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      className={className}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="info-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className="info-dialog-content info-dialog-icon">
          {type === "error" && <ErrorOutlineOutlined className="info-dialog-icon-error" />}
          {type === "success" && (
            <CheckCircleOutlineOutlined className="info-dialog-icon-success" />
          )}
        </DialogContentText>
        <DialogContentText className="info-dialog-content">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <MDLoadingButton
              onClick={handleClose}
              variant="gradient"
              color="dark"
              autoFocus
              fullWidth
            >
              {t("confirm")}
            </MDLoadingButton>
          </Grid>
          <Grid item xs={4} />
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default MDInfoDialog;
