import { forwardRef } from "react";
import { MenuItem, Link } from "@mui/material";
import { MDBox, MDTypography } from "components";
import menuItem from "components/MDMenuItem/styles";

const MDMenuItem = forwardRef(({ icon, title, onClickAction, duljina, ...rest }, ref) => (
  <MenuItem
    {...rest}
    ref={ref}
    sx={(theme) => ({
      ...menuItem(theme),
      ...(duljina && { minWidth: duljina }),
    })}
    onClick={(event) => onClickAction(event)}
  >
    <MDBox component={Link} py={0.5} display="flex" alignItems="center" lineHeight={1}>
      <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
        {icon}
      </MDTypography>
      <MDTypography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
        {title}
      </MDTypography>
    </MDBox>
  </MenuItem>
));

export default MDMenuItem;
