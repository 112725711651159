import { useHTTPClient } from "helper";

const TaxonomiesService = () => {
  const { get, post, del, put } = useHTTPClient();

  const getAllTaxonomies = () => get("Taxonomies/GetAllActive");

  const getTableTaxonomies = ({ pageIndex, pageSize }) =>
    get(`Taxonomies?PageParams.PageIndex=${pageIndex}&PageParams.PageSize=${pageSize}`);

  const createTaxonomy = (data) => post("Taxonomies", data);

  const getTaxonomy = (shortGuid, languageCode) =>
    get(`Taxonomies/GetTaxonomyJson?TaxonomyShortGuid=${shortGuid}&LanguageCode=${languageCode}`);

  const getExtendedTextTag = (projectShortGuid) =>
    get(`Taxonomies/GetTextTagExtendedTaxonomy?projectShortGuid=${projectShortGuid}`);

  const createExtended = (extendedTree) => post(`Taxonomies/CreateExtended`, extendedTree);

  const createExtendedTextTag = (extendedTree) =>
    post(`Taxonomies/CreateTextTagExtension`, extendedTree);

  const getExtended = (reportShortGuid) =>
    get(`Taxonomies/ExtendedTaxonomy?ReportShortGuid=${reportShortGuid}`);

  const deleteTaxonomies = (body) => del("Taxonomies", body);

  const updateTaxonomy = (body) => put("Taxonomies", body);

  const fetchTaxonomy = (shortGuid) =>
    get(`Taxonomies/GetByShortGuid?TaxonomyShortGuid=${shortGuid}`);

  return {
    getAllTaxonomies,
    getTableTaxonomies,
    createTaxonomy,
    getTaxonomy,
    getExtendedTextTag,
    createExtended,
    createExtendedTextTag,
    getExtended,
    deleteTaxonomies,
    updateTaxonomy,
    fetchTaxonomy,
  };
};

export default TaxonomiesService;
