import { lazy } from "react";
import { Icon } from "@mui/material";
import { AuthGuard, PublicRoute } from "components";

const Taxonomies = lazy(() => import("pages/Private/XBRL/Taxonomies"));
const Projects = lazy(() => import("pages/Private/XBRL/Projects"));
const Companies = lazy(() => import("pages/Private/Administration/Companies"));
const ProjectDetails = lazy(() => import("pages/Private/XBRL/Projects/Details"));
const Mapping = lazy(() => import("pages/Private/XBRL/Mapping"));
const CreateReport = lazy(() => import("pages/Private/XBRL/CreateReport"));
const ReportsTable = lazy(() => import("pages/Private/XBRL/Projects/ReportsPage/ReportsTable"));
const Login = lazy(() => import("pages/Public/Login"));
const ResetPassword = lazy(() => import("pages/Public/Login/reset-password/cover"));
const SubmitPassword = lazy(() => import("pages/Public/Login/submit-password/cover"));

const routes = ({ t }) => [
  {
    type: "collapse",
    name: "XBRL",
    key: "app",
    icon: <Icon>description</Icon>,
    collapse: [
      {
        name: t("menu.taxonomies"),
        key: "taxonomies",
        route: "/app/taxonomies",
        menuPermission: "mnuTaxonomies",
        component: (
          <AuthGuard>
            <Taxonomies />
          </AuthGuard>
        ),
      },
      {
        name: t("menu.projects"),
        key: "projects",
        route: "/app/projects",
        component: <Projects />,
      },
    ],
  },
  {
    type: "collapse",
    name: t("menu.administration"),
    key: "administration",
    icon: <Icon>admin_panel_settings</Icon>,
    menuPermission: "mnuAdministration",
    collapse: [
      {
        name: t("menu.companies"),
        key: "companies",
        route: "/administration/companies",
        menuPermission: "mnuCompanies",
        component: (
          <AuthGuard>
            <Companies />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    name: t("menu.projectDetails"),
    key: "project-details",
    route: "/app/project/details/:id",
    component: (
      <AuthGuard>
        <ProjectDetails />
      </AuthGuard>
    ),
  },
  {
    name: t("menu.mapping"),
    key: "mapping",
    route: "/app/mapping",
    component: (
      <AuthGuard>
        <Mapping key="mapping" />
      </AuthGuard>
    ),
  },
  {
    name: t("menu.createreport"),
    key: "create-report",
    route: "/app/project/details/:id/create-report",
    component: (
      <AuthGuard>
        <CreateReport />
      </AuthGuard>
    ),
  },
  {
    name: t("menu.reporttable"),
    key: "report-table",
    route: "/app/project/details/:id/create-report/report-table",
    component: (
      <AuthGuard>
        <ReportsTable />
      </AuthGuard>
    ),
  },
  {
    name: "Login",
    key: "login",
    route: "*",
    component: (
      <PublicRoute>
        <Login />
      </PublicRoute>
    ),
  },
  {
    name: "Reset Password",
    key: "reset-password",
    route: "/reset-password",
    component: (
      <PublicRoute>
        <ResetPassword />
      </PublicRoute>
    ),
  },
  {
    name: "Submit Password",
    key: "submit-password",
    route: "/submit-password",
    component: (
      <PublicRoute>
        <SubmitPassword />
      </PublicRoute>
    ),
  },
];

export default routes;
