const SearchFunctions = () => {
  const searchNode = (nodeId, tree) => {
    let foundNode = null;

    tree.some((node) => {
      if (node.id === nodeId) {
        foundNode = node;
        return true;
      }

      if (node.children && node.children.length > 0) {
        foundNode = searchNode(nodeId, node.children);
        return foundNode !== null;
      }

      return false;
    });
    return foundNode;
  };

  return {
    searchNode,
  };
};
export default SearchFunctions;
