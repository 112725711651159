import { useTranslation } from "react-i18next";
import { Backdrop } from "@mui/material";
import { styled, keyframes } from "@mui/system";
import { MDTypography } from "components";

const FullScreenBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const dotAnimation = keyframes`
    0%, 80%, 100% { opacity: 0; }
    40% { opacity: 1; }
  `;

const Dot = styled("span")(() => ({
  animation: `${dotAnimation} 1.2s infinite`,
}));

const DOTS = ["DOT-1", "DOT-2", "DOT-3"];

function AuthenticatingUserLoader() {
  const { t } = useTranslation();
  return (
    <FullScreenBackdrop open>
      <MDTypography variant="h3">
        {t("authenticatinguserloader")}
        {DOTS.map((i, idx) => (
          <Dot key={i} style={{ animationDelay: `${0.2 * idx}s` }}>
            .
          </Dot>
        ))}
      </MDTypography>
    </FullScreenBackdrop>
  );
}

export default AuthenticatingUserLoader;
