import { useHTTPClient } from "helper";

const AdditionalDocuments = () => {
  const { get, postDoc } = useHTTPClient();

  const getAdditionalDocument = async (documentId) =>
    get(`/AdditionalDocuments/DownloadHtml?documentId=${documentId}`);

  const additionalDocuments = async (projectShortGuid, file) =>
    postDoc(`AdditionalDocuments?projectShortGuid=${projectShortGuid}`, file, {}, null, "pdfFiles");

  return { getAdditionalDocument, additionalDocuments };
};

export default AdditionalDocuments;
