import { useHTTPClient } from "helper";

const CopyMappingsService = () => {
  const { get, post } = useHTTPClient();

  const getMatchingRowsAndColumns = async ({ source, target }) =>
    get(
      `CopyMappings/GetMatchingRowsAndColumns?SourceReportShortGuid=${source}&TargetReportShortGuid=${target}`
    );

  const getMatchedCells = async (body) => post("CopyMappings/GetMatchedCells", body);

  return { getMatchingRowsAndColumns, getMatchedCells };
};

export default CopyMappingsService;
